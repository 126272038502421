<template>
    
    <div class="hp-main-layout-content">

                <div class="row mb-32 gy-32">

                    <div class="col-12">
                        <div class="row g-32">

                            <div class="col-12">
                                <div class="hp-bg-black-bg py-32 py-sm-64 px-24 px-sm-48 px-md-80 position-relative overflow-hidden hp-page-content" style="border-radius: 32px;">
                                    <svg width="358" height="336" fill="none" xmlns="http://www.w3.org/2000/svg" class="position-absolute hp-rtl-scale-x-n1 dd-none" style="bottom: 0px; right: 0px;">
                                        <path d="M730.404 135.471 369.675-6.641l88.802 164.001-243.179-98.8 246.364 263.281-329.128-126.619 114.698 166.726-241.68-62.446" stroke="url(#a)" stroke-width="40" stroke-linejoin="bevel"></path>
                                        <defs>
                                            <linearGradient id="a" x1="515.467" y1="26.875" x2="397.957" y2="337.724" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#fff"></stop>
                                                <stop offset="1" stop-color="#ffffff" stop-opacity="0"></stop>
                                            </linearGradient>
                                        </defs>
                                    </svg>

                                    <div class="row" style="padding-top: 10px; padding-bottom: 10px;">
                                        <div class="col-sm-12 col-12">

                                            <div class="row" style="z-index: 9;">
                                                <div class="col-12">
                                                    <h1 class="mb-0 hp-text-color-black-0"> Crypto Assets </h1>
                                                    <p class=" mb-0 mt-12 hp-text-color-black-60 assetText" >Send and receive crypto at lightning speeds. Easily manage and swap crypto with ease. Earn rewards with staking and lending.   </p>

                                                    <a href="https://www.moonpay.com" target="_blank">
                                                        <button class="btn btn-primary" style="margin-top: 20px; color: #000000;">Buy crypto</button>
                                                    </a>

                                                </div>

                                                
                                            </div>

                                            <br>
                                            <hr>
                                            <br>

                                            <!-- STATS HERE -->
                                            <div class="row g-32">

                                            <div class="col-12 col-sm-3">
                                                <div class="row gx-8 gy-16">
                                                    <div class="col hp-flex-none w-auto">
                                                        <div class="avatar-item d-flex align-items-center justify-content-center avatar-lg bg-warning-4 hp-bg-dark-warning rounded-circle">
                                                            <i class="ri-money-dollar-circle-line text-warning " style="font-size: 24px;"></i>
                                                        </div>
                                                    </div>

                                                    <div class="col hp-flex-none w-auto">
                                                        <h5 class="mb-4">${{ totalBalanceD }}</h5>
                                                        <p class="hp-badge-text mb-0 text-black-80 hp-text-color-dark-30">
                                                            Total balance
                                                        </p>
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            <div class="col-6 col-sm-3 dd-none">
                                                <div class="row gx-8 gy-16">
                                                    <div class="col hp-flex-none w-auto">
                                                        <div class="avatar-item d-flex align-items-center justify-content-center avatar-lg bg-primary-4 hp-bg-dark-primary rounded-circle">
                                                            <i class="ri-hand-coin-line text-primary hp-text-color-dark-primary-5" style="font-size: 24px;"></i>
                                                        </div>
                                                    </div>

                                                    <div class="col hp-flex-none w-auto">
                                                        <h5 class="mb-4">{{ totalHeld }} of {{ totalCoins }}</h5>
                                                        <p class="hp-badge-text mb-0 text-black-80 hp-text-color-dark-30" >
                                                            Assets held
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- <div class="col-6 col-sm-2" >
                                                <div class="row gx-8 gy-16">
                                                    <div class="col hp-flex-none w-auto">
                                                        <div class="avatar-item d-flex align-items-center justify-content-center avatar-lg bg-primary-4 hp-bg-dark-secondary rounded-circle">
                                                            <i class="ri-switch-line text-secondary hp-text-color-dark-primary-5" style="font-size: 24px;"></i>
                                                        </div>
                                                    </div>

                                                    <div class="col hp-flex-none w-auto">
                                                        <h5 class="mb-4">{{ totalTrans }}</h5>
                                                        <p class="hp-badge-text mb-0 text-black-80 hp-text-color-dark-30" >
                                                            Transactions
                                                        </p>
                                                    </div>
                                                </div>
                                            </div> -->

                                            <div class="col-6 col-sm-3 dd-none">
                                                <div class="row gx-8 gy-16">
                                                    <div class="col hp-flex-none w-auto">
                                                        <div class="avatar-item d-flex align-items-center justify-content-center avatar-lg bg-secondary-4 hp-bg-dark-success rounded-circle">
                                                            <i class="ri-2x ri-user-received-line text-success" style="font-size: 24px;"></i>
                                                        </div>
                                                    </div>

                                                    <div class="col hp-flex-none w-auto">
                                                        <h5 class="mb-4">${{ totalReceived }}</h5>
                                                        <p class="hp-badge-text mb-0 text-black-80 hp-text-color-dark-30">
                                                           Received
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-6 col-sm-3 dd-none">
                                                <div class="row gx-8 gy-16">
                                                    <div class="col hp-flex-none w-auto">
                                                        <div class="avatar-item d-flex align-items-center justify-content-center avatar-lg bg-danger-4 hp-bg-dark-danger rounded-circle">
                                                            <i class="ri-user-shared-2-line text-danger" style="font-size: 24px;"></i>
                                                        </div>
                                                    </div>

                                                    <div class="col hp-flex-none w-auto">
                                                        <h5 class="mb-4">${{ totalSent }}</h5>
                                                        <p class="hp-badge-text mb-0 text-black-80 hp-text-color-dark-30">
                                                            Sent
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            
                                        </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-12">
                                <div class="row g-32">
                                    
                                    <div class="col-12">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="d-flex align-items-end mb-24">
                                                    <h4 class="me-8">Balance Chart</h4>
                                                    <p class="hp-badge-text"></p>
                                                </div>

                                                <div id="column-chart"></div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div> -->

                            

                            <div class="col-12" style="padding-left: 60px; padding-right: 60px; margin-bottom: -20px;">
                                <div class="row g-32">
                                            <div class="col-6 col-sm-2" >
                                                <div class="row gx-8 gy-16" >
                                                    

                                                    <div class="col hp-flex-none w-auto">
                                                        <p class="hp-badge-text mb-0 text-black-80 hp-text-color-dark-30">Name</p>
                                                        
                                                    </div>

                                                    

                                                </div>
                                            </div>

                                            <div class="col-6 col-sm-2" >
                                                <div class="row gx-8 gy-16" >
                                                    

                                                    <div class="col hp-flex-none w-auto">
                                                        <p class="hp-badge-text mb-0 text-black-80 hp-text-color-dark-30">Balance</p>
                                                        
                                                    </div>

                                                    

                                                </div>
                                            </div>

                                            <div class="col-6 col-sm-2 dd-none" >
                                                <div class="row gx-8 gy-16" >
                                                    

                                                    <div class="col hp-flex-none w-auto">
                                                        <p class="hp-badge-text mb-0 text-black-80 hp-text-color-dark-30">Price</p>
                                                        
                                                    </div>

                                                    

                                                </div>
                                            </div>

                                            <!-- <div class="col-6 col-sm-2 dd-none" >
                                                <div class="row gx-8 gy-16" >
                                                    

                                                    <div class="col hp-flex-none w-auto">
                                                        <p class="hp-badge-text mb-0 text-black-80 hp-text-color-dark-30">Chart</p>
                                                        
                                                    </div>

                                                    

                                                </div>
                                            </div> -->

                                            <div class="col-6 col-sm-3 dd-none" >
                                                <div class="row gx-8 gy-16" >
                                                    

                                                    <div class="col hp-flex-none w-auto">
                                                        <p class="hp-badge-text mb-0 text-black-80 hp-text-color-dark-30">24hr Volume</p>
                                                        
                                                    </div>

                                                    

                                                </div>
                                            </div>

                                    
                                            <div class="col-6 col-sm-3 dd-none" >
                                                <div class="row gx-8 gy-16" >
                                                    

                                                    <div class="col hp-flex-none w-auto">
                                                        <p class="hp-badge-text mb-0 text-black-80 hp-text-color-dark-30">Market Cap/Supply</p>
                                                        
                                                    </div>

                                                    

                                                </div>
                                            </div>

                                            

                                        </div>
                            </div>

                            <div class="col-12" style="cursor: pointer ; margin-bottom: -25px;" v-for="coin in coinList" :key="coin.walletId" @click="goToInfo(coin)">
                                <div class="card hp-crd-2">
                                    <div class="card-body coinBox" style="width: 100%;">
                                        

                                        <div class="row g-32">
                                            <div class="col-6 col-sm-2">
                                                <div class="row gx-8 gy-16">
                                                    <div class="col hp-flex-none w-auto">
                                                        <div class="avatar-item d-flex align-items-center justify-content-center avatar-lg bg-primary-4 hp-bg-dark rounded-circle" style="background-color: #141414;" >
                                                            <img :src="coin.image" alt="" style="width: 100%; background-size: cover; background-position: center; border-radius: 50%;  ">
                                                        </div>
                                                    </div>

                                                    <div class="col hp-flex-none w-auto">

                                                        <h5 class="mb-4">{{ coin.symbol }}</h5>
                                                        <p class="hp-badge-text mb-0 text-black-80 hp-text-color-dark-30" v-if="coin.symbol=='ETC'" >
                                                            {{ coin.abr }}
                                                        </p>
                                                        <p class="hp-badge-text mb-0 text-black-80 hp-text-color-dark-30" v-else >
                                                            {{ coin.name }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            

                                            <div class="col-6 col-sm-2" >
                                                <div class="row gx-8 gy-16" >
                                                    

                                                    <div class="col hp-flex-none w-auto">
                                                        <h5 class="mb-4">${{ coin.balanceUsdD }}</h5>
                                                        <p class="hp-badge-text mb-0 text-black-80 hp-text-color-dark-30">
                                                            {{ coin.balanceD }} {{ coin.symbol }}
                                                        </p>
                                                    </div>

                                                    

                                                </div>
                                            </div>

                                            

                                            <div class="col-6 col-sm-2 dd-none" >
                                                <div class="row gx-8 gy-16">
                                                    

                                                    <div class="col hp-flex-none w-auto">
                                                        <h5 class="mb-4">{{ coin.priceD }}</h5>
                                                        <p class="hp-badge-text mb-0  text-start text-success" v-if="coin.change > 0">
                                                         +{{ coin.changeD }}%
                                                        </p>
                                                        <p class="hp-badge-text mb-0  text-start text-danger" v-if="coin.change < 0">
                                                         {{ coin.changeD }}%
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- <div class="col-6 col-sm-2 dd-none">
                                                <div class="row gx-8 gy-16">
                                                    

                                                    <div class="col hp-flex-none w-auto">
                                                        <img :src="coin.chartImg" alt="">
                                                    </div>
                                                </div>
                                            </div> -->

                                            <div class="col-6 col-sm-3 dd-none">
                                                <div class="row gx-8 gy-16">
                                                    

                                                    <div class="col hp-flex-none w-auto">
                                                        <h5 class="mb-4">${{ coin.volume }}</h5>
                                                        <p class="hp-badge-text mb-0 text-black-80 hp-text-color-dark-30 text-center">
                                                            {{ coin.openPrice }} {{ coin.symbol }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                           <div class="col-6 col-sm-3 dd-none">
                                                <div class="row gx-8 gy-16">
                                                    

                                                    <div class="col hp-flex-none w-auto">
                                                        <h5 class="mb-4">${{ coin.marketCap }}</h5>
                                                        <p class="hp-badge-text mb-0 text-black-80 hp-text-color-dark-30 text-center">
                                                            {{ coin.supply }} {{ coin.symbol }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

                <!-- SUCCESS -->
                <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="successModal">

                    <div class="col-12 col-xl-4" style="width: 300px;">
                        <div class="card hp-card-6">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <img src="@/assets/img/success.png" alt="Upgrade Account" class="hp-dark-block mx-auto">

                                        <h3 class="mt-24 mb-16 me-4 text-success">Success!</h3>
                                        <p class="hp-p1-body mb-0" style="margin-top: -10px;">{{ successText }}</p>

                                        <button class="btn btn-sm btn-primary" style="color: #000000; font-weight: 500; border-style: none; margin-top: 20px;" @click="closeS">Okay</button>

                                    </div>

                                    

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- ERROR -->
                <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg " ref="errorModal">

                    <div class="col-12 col-xl-4" style="width: 300px;">
                        <div class="card hp-card-6">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <img src="@/assets/img/error.png" alt="Upgrade Account" class="hp-dark-block mx-auto">

                                        <h3 class="mt-24 mb-16 me-4 text-danger">Error!</h3>
                                        <p class="hp-p1-body mb-0" style="margin-top: -10px;">{{ errorText }}</p>

                                        <button class="btn btn-sm btn-primary" style="color: #000000; font-weight: 500; border-style: none; margin-top: 20px;" @click="closeE">Okay</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- LOADING -->
                <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="loadingModal">

                    <div class="col-12 col-xl-4" style="width: 300px;">
                        <div class="card hp-card-6">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <img src="@/assets/img/loading.png"  class="hp-dark-block mx-auto" >

                                        <!-- <h3 class="mt-24 mb-16 me-4 text-danger"></h3> -->

                                        <div class="spinner-border" style="width: 3rem; height: 3rem; border-color: #cbfb45;" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>

                                        <p class="hp-p1-body mb-0" style="margin-top: 10px;">{{ loadingText }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

</template>

<style scoped>

    .assetText{
        padding-right: 450px;
    }

    @media only screen and (max-width: 700px){
        .assetText{
            padding-right: 50px;
        }
    }

</style>

<script>
    import { doc, getDoc, addDoc, collection, query, where, getDocs, orderBy, updateDoc } from "firebase/firestore";
    import { db } from '@/firebase';
    import axios from 'axios';

    export default {

        mounted(){

            this.loadingText = "Preparing dashboard...";
            this.$refs.loadingModal.classList.add("bg-active");

            document.getElementById("assetsN").classList.add("active");
            document.getElementById("swapN").classList.remove("active");
            document.getElementById("stakingN").classList.remove("active");
            document.getElementById("transN").classList.remove("active");
             document.getElementById("lendingN").classList.remove("active");
            document.getElementById("contractN").classList.remove("active");

            document.getElementById("assetsN2").classList.add("active");
            document.getElementById("swapN2").classList.remove("active");
            document.getElementById("stakingN2").classList.remove("active");
            document.getElementById("transN2").classList.remove("active");
            document.getElementById("lendingN2").classList.remove("active");
            document.getElementById("contractN2").classList.remove("active");

            this.getStats();
        },

        data(){
            return{
                laodingText: "",
                successText: "",
                errorText: "",
                totalTrans: "",
                totalSent: "",
                totalReceived: "",
                totalBalance: 0,
                totalBalanceD: "",
                totalCoins: 0,
                totalHeld: 0,
                coinList: [],
            }
        },

        methods: {

            closeS(){
                this.$refs.successModal.classList.remove("bg-active");
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            round(num, decimals){
                return +(Math.round(num + "e" + decimals) + "e-" + decimals);
            },

            async getStats(){

                let urlString = String(window.location.href);
                let url = new URL(urlString);
                let userId = url.searchParams.get("wid");

                
            
                if(userId != null){
                    sessionStorage.setItem("id", String(userId));
                }

                const docRef = doc(db, "Users", sessionStorage.getItem("id"));
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    this.totalSent = docSnap.data().totalSent;
                    this.totalReceived = docSnap.data().totalReceived;
                    this.totalTrans = docSnap.data().totalTrans;

                    sessionStorage.setItem("name", String(docSnap.data().name));
                    sessionStorage.setItem("profilePic", String(docSnap.data().profilePic));
                    sessionStorage.setItem("email", String(docSnap.data().email));
                    sessionStorage.setItem("waveStakeId", docSnap.data().waveStakeId);

                    document.getElementById("profileName").textContent = String(docSnap.data().name);
                    document.getElementById("profilePic").src = String(docSnap.data().profilePic);
                    document.getElementById("profilePic2").src = String(docSnap.data().profilePic); 
                } 
                else {
                    this.$refs.loadingModal.classList.remove("bg-active");
                    window.location.replace("https://account.wavestake.com/sign-in");
                    return;
                }

                this.getCoinSymbols();

            },

            async getCoinSymbols(){

               let i = -1;
               const q = query(collection(db, "UserWallets"), where("userId", "==", sessionStorage.getItem("id")), orderBy("balance", "desc")); 
               const querySnapshot = await getDocs(q); 

               querySnapshot.forEach((doc) => {
                    i++;
                    this.totalCoins++;
                    this.getCoinList(i, doc);
               })
               

               //close loader
               if(i > 13){
                this.$refs.loadingModal.classList.remove("bg-active");
               }
            },

            async getCoinList(i, doc){

                let price, priceD,  marketCap, change, changeD, image, volume, openPrice, supply, balance, balanceUsd, openDay, lowDay, highDay;

                balance = doc.data().balance;

                if(balance < 0){
                    //update balance to 0
                    balance = 0;
                    const walletRef = doc(db, "UserWallets", String(doc.id));
                    await updateDoc(walletRef, {
                        balance: 0
                    });
                }
                
                if(balance > 0){
                    this.totalHeld++;
                }

                const response = await axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+doc.data().symbol+'&tsyms=USD');

                price = Object.values(response.data.RAW)[0].USD.PRICE;
                priceD = Object.values(response.data.DISPLAY)[0].USD.PRICE;
                openDay = Object.values(response.data.DISPLAY)[0].USD.OPEN24HOUR;
                lowDay = Object.values(response.data.DISPLAY)[0].USD.LOW24HOUR;
                highDay = Object.values(response.data.DISPLAY)[0].USD.HIGH24HOUR;
                marketCap = Object.values(response.data.RAW)[0].USD.MKTCAP;
                change = Object.values(response.data.RAW)[0].USD.CHANGEPCTDAY;
                changeD = Object.values(response.data.DISPLAY)[0].USD.CHANGEPCTDAY;
                image = "https://www.cryptocompare.com"+Object.values(response.data.RAW)[0].USD.IMAGEURL;
                volume = Object.values(response.data.RAW)[0].USD.VOLUME24HOURTO;
                openPrice = Object.values(response.data.RAW)[0].USD.VOLUME24HOUR;
                supply = Object.values(response.data.RAW)[0].USD.SUPPLY;

                //replace image

                if(doc.data().symbol == "BTC"){
                    image="https://s2.coinmarketcap.com/static/img/coins/200x200/1.png"
                }
                else if(doc.data().symbol == "BCH"){
                    image="https://s2.coinmarketcap.com/static/img/coins/200x200/1831.png"
                }
                else if(doc.data().symbol == "XRP"){
                    image="https://s2.coinmarketcap.com/static/img/coins/200x200/52.png"
                }
                else if(doc.data().symbol == "ETC"){
                    image="https://s2.coinmarketcap.com/static/img/coins/200x200/1321.png"
                }
                else if(doc.data().symbol == "AAVE"){
                    image="https://s2.coinmarketcap.com/static/img/coins/200x200/7278.png"
                }
                else if(doc.data().symbol == "BNB"){
                    image="https://s2.coinmarketcap.com/static/img/coins/200x200/1839.png"
                }
                else if(doc.data().symbol == "SHIB"){
                    image="https://assets.coingecko.com/coins/images/11939/large/shiba.png?1622619446"
                }
                else if(doc.data().symbol == "ATOM"){
                    image="https://s2.coinmarketcap.com/static/img/coins/200x200/3794.png"
                }
                else if(doc.data().symbol == "USDT"){
                    image="https://static.crypto.com/token/icons/tether/color_icon.png"
                }
                else if(doc.data().symbol == "DOT"){
                    image="https://s2.coinmarketcap.com/static/img/coins/200x200/6636.png"
                }
                else if(doc.data().symbol == "TRX"){
                    image="https://s2.coinmarketcap.com/static/img/coins/200x200/1958.png"
                }
                else if(doc.data().symbol == "MATIC"){
                    image="https://s2.coinmarketcap.com/static/img/coins/200x200/3890.png"
                }
                else if(doc.data().symbol == "ETH"){
                    image="https://cloudfront-us-east-1.images.arcpublishing.com/coindesk/ZJZZK5B2ZNF25LYQHMUTBTOMLU.png"
                }
                else if(doc.data().symbol == "SOL"){
                    image="https://s2.coinmarketcap.com/static/img/coins/200x200/5426.png"
                }

                //get totalBalance
                balanceUsd = price * balance;
                this.totalBalance +=balanceUsd;
                this.totalBalanceD = this.totalBalance.toLocaleString("us-US")

                this.coinList[i] = {
                    abr: doc.data().abr,
                    address: doc.data().address,
                    balance: balance,
                    balanceD: this.round(balance, 6),
                    balanceUsd: balanceUsd,
                    balanceUsdD: balanceUsd.toLocaleString("us-US"),
                    chartImg: doc.data().chartImg+".svg",
                    color: doc.data().color,
                    description: doc.data().description,
                    name: doc.data().name,
                    symbol: doc.data().symbol,
                    rampId: doc.data().rampId,
                    walletId: doc.id,
                    price: price,
                    priceD: priceD,
                    marketCap: this.round(marketCap, 0),
                    marketCapI: marketCap.toLocaleString("us-US"),
                    change: change,
                    changeD: changeD,
                    image: image,
                    qrcode: "https://quickchart.io/qr?text="+doc.data().address,
                    volume: this.round(volume, 0),
                    volumeI: volume.toLocaleString("us-US"),
                    openPrice: openPrice.toLocaleString("us-US"),
                    supply: supply.toLocaleString("us-US"),
                    openDay: openDay,
                    lowDay: lowDay,
                    highDay: highDay, 

                    }
                
                
            },

            goToInfo(coin){
                sessionStorage.setItem("coinName", coin.name);
                sessionStorage.setItem("coinPrice", coin.price);
                sessionStorage.setItem("coinDescription", coin.description);
                sessionStorage.setItem("coinPriceD", coin.priceD);
                sessionStorage.setItem("coinChange", coin.change);
                sessionStorage.setItem("coinChangeD", coin.changeD);
                sessionStorage.setItem("coinImage", coin.image);
                sessionStorage.setItem("coinQrcode", coin.qrcode);
                sessionStorage.setItem("coinMarketCap", coin.marketCapI);
                sessionStorage.setItem("coinVolume", coin.volumeI);
                sessionStorage.setItem("coinSupply", coin.supply);
                sessionStorage.setItem("coinSymbol", coin.symbol);
                sessionStorage.setItem("coinWalletId", coin.walletId);
                sessionStorage.setItem("openDay", coin.openDay);
                sessionStorage.setItem("lowDay", coin.lowDay);
                sessionStorage.setItem("highDay", coin.highDay);
                sessionStorage.setItem("coinColor", coin.color);
                sessionStorage.setItem("coinAddress", coin.address);
                sessionStorage.setItem("rampId", coin.rampId);

                this.$router.push({name: 'assetInfo'});


            }
        }
    }
</script>