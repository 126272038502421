import { createRouter, createWebHistory } from 'vue-router'
import AssetsView from '../views/AssetsView.vue'

const routes = [
  {
    path: "/",
    name: "assets",
    component: AssetsView,
  },
  {
    path: "/assets",
    name: "assets2",
    component: AssetsView,
  },
  {
    path: "/asset-info",
    name: "assetInfo",
    component: () => import("../views/AssetInfoView.vue"),
  },

  {
    path: "/swap",
    name: "swap",
    component: () => import("../views/SwapView.vue"),
  },

  {
    path: "/stakings",
    name: "stakings",
    component: () => import("../views/StakingView.vue"),
  },

  {
    path: "/lending",
    name: "lending",
    component: () => import("../views/LendingView.vue"),
  },

  {
    path: "/contract-staking",
    name: "contract",
    component: () => import("../views/ContractView.vue"),
  },

  {
    path: "/transactions",
    name: "trans",
    component: () => import("../views/TransView.vue"),
  },

  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/ProfileView.vue"),
  },

  {
    path: "/:catchAll(.*)",
    name: "notfound",
    component: () => import("../views/NotFoundView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
