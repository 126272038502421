<template>
  <AssetsBody/>
</template>

<script>
// @ is an alias to /src
import AssetsBody from '@/components/AssetsBody.vue'

export default {
  name: 'AssetsView',
  components: {
    AssetsBody
  }
}
</script>
